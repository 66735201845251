/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Layout from "../components/Layout";
import ActivityCardGrid from "../components/ActivityCardGrid";
import Separator from "../components/Separator";
import ActivityGroupSections from "../components/ActivityGroupSections";

import { AlmostThere } from "../assets/images/brailleheaders";

const ActivityGroup = ({
  pageContext: { activityGroup, activities, allActivityGroups },
}) => (
  <Layout
    title={activityGroup.seo.title || activityGroup.name}
    description={activityGroup.seo.description}
    breadcrumbsMap={{
      [activityGroup.uid]: activityGroup.name,
    }}
    brailleTextImage={AlmostThere}
  >
    <Themed.h1
      sx={{ variant: "text.h2" }}
    >{`${activityGroup.level} ${activityGroup.name}`}</Themed.h1>
    <Themed.p sx={{ marginTop: "40px", marginBottom: "80px" }}>
      {activityGroup.description}
    </Themed.p>
    <ActivityCardGrid
      activities={activities}
      cardTitleHeadingComponent={Themed.h2}
    />
    <Separator />
    <ActivityGroupSections activityGroups={allActivityGroups} />
  </Layout>
);

export default ActivityGroup;
